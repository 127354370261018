import React from "react";
import styled from "styled-components";
import logo from "../../assets/images/logo-full.png";

const WithStyle = styled.div`
  display: flex;
  /* position: absolute; */
  width: 100%;
  bottom: 0;
  background-color: #071563;
  min-height: 110px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`;

const Logo = styled.img`
  width: 170px;
  margin: 0 1rem;
`;

const Content = styled.span`
  position: relative;
  margin: 0 1rem;
  color: #ffffff;
`;

const Link = styled.a`
  margin: 0 1rem;
  color: #ffffff;
  text-decoration: none;
`;

const Footer = () => {
  return (
    <WithStyle>
      <Logo src={logo} alt="ידידים הסדרים פנסיוניים סוכנות ביטוח בע&quot;מ" />
      <Link href="/accessibility-statement">הצהרת נגישות</Link>
      <Link href="/sitemap">מפת האתר</Link>
      <Content>כל הזכויות שמורות ©</Content>
    </WithStyle>
);
};

export default Footer;
