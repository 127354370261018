import styled from "styled-components";

const LinkWrapper = styled.div`
  float: right;
  padding: 0px 15px;
  height: 85px;
  display: flex;
  align-items: center;
  & a {
    height:  35px;
  }
  @media (min-width: 890px) {
    &.logo a {
      height: 64px;
    }
  }
`;

export default LinkWrapper;
