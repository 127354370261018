import "core-js";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import "reset-css/reset.css";
import React from "react";
import { createRoot } from 'react-dom/client';
import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Home from "./routes/home";
import About from "./routes/about";
import Customer from "./routes/customer";
import StructureOrganization from "./routes/structure-organization";
import Product from "./routes/product";
import Disclosure from "./routes/disclosure";
import Package from "./routes/package";
import Pension from "./routes/pension-management";
import Forms from "./routes/forms";
import Abroad from "./routes/abroad";
import Login from "./routes/login";
import Collectives from "./routes/collectives";
import Accompaniment from "./routes/accompaniment-circle";
import AccessibilityStatement from "./routes/accessibility-statement";
import Sitemap from "./routes/sitemap";

import Header from "./components/Header";
import Footer from "./components/Footer";

import * as serviceWorker from "./serviceWorker";
import FinishWork from "./routes/finish-work";
import StartNewWork from "./routes/start-new-work/StartNewWork";
import LifeChanges from "./routes/life-changes/LifeChanges";
import RetirementProcess from "./routes/retirement-process";
import ProcessHealthInsurance from "./routes/process-health-insurance";
import { getMenuPanel } from "./services/menuPanel";
import InsuranceProtections from "./routes/insurance-protections";
import Thales from "./routes/thales";
import Service from "./routes/service";
import ScrollToTop from "./services/scrollTop";
import { maslul_bituah_67_pensiya, yedidim20200719_flayer, f5_sofi_5e6r, takchir_bituah_briut_2020, letter_to_a_new_employee,
  pdf_inviting_for_a_meeting_1, pdf_41245_74_Flyer_c_sofi_mail, pdf_197E_generic_job_termination_letter, pdf_inviting_for_a_meeting_2,
  pdf_inviting_for_a_meeting_3, pdf_you_have_a_temporary_risk, pdf_termination_letter_2024, pdf_private_contact_details,pdf_46582_10_Hoveret_a_sofi } from "./assets/pdf";

import Redirect from "./components/Redirect";

import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';

const datas = getMenuPanel();

// https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
// This implements the default behavior from styled-components v5
function shouldForwardProp(propName, target) {
  if (typeof target === "string") {
      // For HTML elements, forward the prop if it is a valid HTML attribute
      return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

const routing = (
  <StyleSheetManager shouldForwardProp={shouldForwardProp}>
    <Router>
      <div
        style={{ position: "relative", overflow: "hidden", minHeight: "100vh" }}
      >
        <Header data={datas} />
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/customer" element={<Customer />} />
          <Route
            exact
            path="/structure-organization"
            element={<StructureOrganization />}
          />
          <Route exact path="/product" element={<Product />} />
          <Route exact path="/disclosure" element={<Disclosure />} />
          <Route exact path="/package" element={<Package />} />
          <Route exact path="/pension-management" element={<Pension />} />
          <Route exact path="/forms" element={<Forms />} />

          <Route exact path="/finish-work" element={<FinishWork />} />
          <Route exact path="/start-new-work" element={<StartNewWork />} />
          <Route exact path="/life-changes" element={<LifeChanges />} />
          <Route exact path="/retirement-process" element={<RetirementProcess />} />
          <Route exact path="/process-health-insurance" element={<ProcessHealthInsurance />} />

          <Route exact path="/abroad" element={<Abroad />} />
          <Route exact path="/collective/:id" element={<Login />} />
          <Route exact path="/collectives" element={<Collectives />} />
          <Route exact path="/accompaniment-circle" element={<Accompaniment />} />
          <Route exact path="/insurance-protections" element={<InsuranceProtections />} />
          <Route exact path="/thales" element={<Thales />} />
          <Route exact path="/service" element={<Service />} />
          <Route exact path="/pdf/231F" element={<Redirect url={ pdf_46582_10_Hoveret_a_sofi } />} />
          <Route path="/pdf/280B" element={<Redirect url={ 'https://files7.design-editor.com/91/9147721/UploadedFiles/6EE23BDD-C8E9-806F-416C-3DB898C0280B.pdf' } />} />
          <Route path="/pdf/197E" element={<Redirect url={ pdf_197E_generic_job_termination_letter } />} />
          <Route exact path="/pdf/1B45" element={<Redirect url={ pdf_inviting_for_a_meeting_2 } />} />
          <Route exact path="/pdf/210D" element={<Redirect url={ pdf_inviting_for_a_meeting_3 } />} />
          <Route exact path="/pdf/4525" element={<Redirect url={ pdf_41245_74_Flyer_c_sofi_mail } />} />
          <Route exact path="/pdf/48E4" element={<Redirect url={ pdf_you_have_a_temporary_risk } />} />
          <Route exact path="/pdf/4700" element={<Redirect url={ pdf_termination_letter_2024 } />} />
          <Route path="/pdf/5E6R" element={<Redirect url={ f5_sofi_5e6r } />} />
          <Route path="/pdf/6YZR" element={<Redirect url={ yedidim20200719_flayer } />} />
          <Route path="/pdf/8K7L" element={<Redirect url={ maslul_bituah_67_pensiya } />} />
          <Route path="/pdf/4K5SL" element={<Redirect url={ takchir_bituah_briut_2020 } />} />
          <Route path="/pdf/9W5GL" element={<Redirect url={ letter_to_a_new_employee } />} />
          <Route exact path="/pdf/A30D" element={<Redirect url={ pdf_inviting_for_a_meeting_1 } />} />
          <Route exact path="/pdf/E0EA" element={<Redirect url={ pdf_private_contact_details } />} />
          <Route exact path="/accessibility-statement" element={<AccessibilityStatement />} />
          <Route exact path="/sitemap" element={<Sitemap />} />
          <Route exact path="*" element={<Home />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  </StyleSheetManager>
);

const container = document.getElementById('root');
const reactRoot = createRoot(container);
reactRoot.render(routing);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
