import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import logoPhone from "../../assets/images/phone.png";
import form from "../../assets/images/form.svg";

const WithStyle = styled.div`
  position: absolute;
  left: 60px;
  height: 100%;
  color: #ffffff;
`;

const Logo = styled.img`
  display: block;
  margin: auto;
  width: 35px;
  height: 35px;
  padding: 15px 15px 8px 15px;
`;

const Section = styled.div.attrs((props) => props)`
  display: inline-block;
  height: 100%;
  padding: 0 10px;
  font-size: 15px;
  text-align: center;
  vertical-align: middle;
  background-color: ${props => props.bgColor};
  ${props =>
    props.gradientSection &&
    "background: linear-gradient(to right,#2245C6,#2251d5);"};
  ${props => props.justDesktop && "@media (max-width:750px) {display:none} "}
  ${props => props.justMobile && "@media (max-width:760px) {display:block} "}
  ${props => props.justMobile && "@media (min-width:761px) {display:none} "}
  & a {
    text-decoration: none;
  }
`;


const InSection = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column; 
`;


const TextLogo = styled.p`
  padding-bottom: 5px;
`;

const CLink = styled.a`
  padding: 0 10px;
  text-decoration: none;
  color: #ffffff;
`;

const LinkSection = props => {
  return (
    <WithStyle>
      <Section justDesktop={true}>
        <InSection>
          <CLink target="_blank" href="https://info.yedidim.co.il/portal/loginEmployer">
            כניסה למעסיקים
          </CLink>
        </InSection>
      </Section>
      <Section gradientSection={true} justDesktop={true}>
        <InSection>
          <CLink target="_blank" href="https://info.yedidim.co.il/AnYedidim/websiteRegistration.aspx">
            כניסה ללקוחות
          </CLink>
        </InSection>
      </Section>
      <Section bgColor="#00B1E1" justDesktop={true}>
        <Link to="/forms">
          <InSection>
            <Logo src={form} />
            <TextLogo>טפסים</TextLogo>
          </InSection>
        </Link>
      </Section>
      <Section bgColor="#73A526" justDesktop={true}>
        <InSection>
          <CLink href="/#section_6">
             <Logo src={logoPhone} />
             <TextLogo>יצירת קשר</TextLogo>
          </CLink>
        </InSection>
      </Section>
      <Section bgColor="#73A526" justMobile={true}>
        <InSection>
          <CLink href="tel:0732186333">
             <Logo src={logoPhone} />
             <TextLogo>יצירת קשר</TextLogo>
          </CLink>
        </InSection>
      </Section>
    </WithStyle>
  );
};

export default LinkSection;
