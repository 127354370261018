export const migdal1 = require("./migdal1.pdf");
export const migdal2 = require("./migdal2.pdf");
export const harel1 = require("./harel1.pdf");
export const harel2 = require("./harel2.pdf");
export const harel3 = require("./harel3.pdf");
export const menora1 = require("./menora1.pdf");
export const menora2 = require("./menora2.pdf");
export const clal1 = require("./clal1.pdf");
export const clal2 = require("./clal2.pdf");
export const phenix1 = require("./phenix1.pdf");
export const phenix2 = require("./phenix2.pdf");
export const phenix3 = require("./phenix3.pdf");
export const phenix4 = require("./phenix4.pdf");
export const meitav = require("./meitav.pdf");
export const psagot = require("./psagot.pdf");
export const yalin = require("./yalin.pdf");

export const clal_pensia = require("./clal_pensia.pdf");
export const harel_pensia = require("./harel_pensia.pdf");
export const altshuler_pensia = require("./altshuler_pensia.pdf");
export const migdal_pensia = require("./migdal_pensia.pdf");
export const mitav_dash_pensia = require("./mitav_dash_pensia.pdf");
export const phenix_pensia = require("./phenix_pensia.pdf");
export const pisgot_pensia = require("./pisgot_pensia.pdf");
export const menora_pensia = require("./menora_pensia.pdf");


export const f5_sofi_5e6r = require("./f5_sofi_5e6r.pdf");
export const yedidim20200719_flayer = require("./yedidim20200719_flayer.pdf");
export const maslul_bituah_67_pensiya = require("./maslul_bituah_67_pensiya.pdf");
export const takchir_bituah_briut_2020 = require("./takchir_bituah_briut_2020.pdf");
export const letter_to_a_new_employee = require("./letter_to_a_new_employee.pdf");

export const pdf_inviting_for_a_meeting_1 = require('./הזמנה_לפגישה_1.pdf');
export const pdf_41245_74_Flyer_c_sofi_mail = require('./41245.74_Flyer_c_sofi_mail.pdf');

export const pdf_197E_generic_job_termination_letter = require('./197E_generic_job_termination_letter.pdf');
export const pdf_inviting_for_a_meeting_2 = require('./הזמנה_לפגישה_2.pdf');
export const pdf_inviting_for_a_meeting_3 = require('./הזמנה_לפגישה_3.pdf');
export const pdf_you_have_a_temporary_risk = require('./חלת-_ריסק_זמני.pdf');
export const pdf_termination_letter_2024 = require('./מכתב_סיום_עבודה.pdf');

export const pdf_private_contact_details = require('./פרטי_התקשרות_שפיר.pdf');

export const pdf_46582_10_Hoveret_a_sofi = require('./46582.10_Hoveret_a_sofi.pdf');
