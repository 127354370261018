import React, { Component } from "react";
import WithStyle from "./Header.style";
import LinkWrapper from "./LinkWrapper";
import Logo from "./Logo";
import LinkSection from "./LinkSection";
import logo from "../../assets/images/logo-full.png";
import { Link } from "react-router-dom";
import BurgerMenu from "./BurgerMenu";

class Header extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <WithStyle>
        <BurgerMenu data={this.props.data} />
        <LinkSection />
        <LinkWrapper className="logo">
          <Link to="/">
            <Logo logo={logo} />
          </Link>
        </LinkWrapper>
      </WithStyle>
    );
  }
}

export default Header;
